import {
    Button,
    Checkbox,
    CSSObject,
    Divider,
    Group,
    MantineTheme,
    Paper,
    Popover,
    Stack,
    Text,
    Title,
} from "@mantine/core";
import { ReactElement, useState } from "react";
import { IconChevronDown } from "@tabler/icons-react";
import { useListState } from "@mantine/hooks";
import { labelToDataId } from "@flexidao/ui-lib";
import { FilterOption } from "./types";
import { getSortedCheckedKeys } from "./utils";

const optionToReactElement = <T extends string>(
    dataIdPrefix: string,
    value: FilterOption<T>,
    onOptionCheckToggle: (checked: boolean) => void,
): ReactElement => (
    <Checkbox
        p="xs"
        pl="md"
        label={value.label}
        key={value.key}
        checked={value.checked}
        onChange={(event): void => {
            onOptionCheckToggle(event.currentTarget.checked);
        }}
        data-id={labelToDataId({
            prefix: dataIdPrefix,
            label: value.label,
        })}
    />
);

type MultiselectFilterProps<T extends string> = {
    title: string;
    selectAllLabel: string;
    initialValues: Array<FilterOption<T>>;
    numOptions: number;
    numAppliedOptions: number | null;
    handleApply: (appliedOptions: Array<T> | null) => void;
    dataIdPrefix: string;
    disabled?: boolean;
};

export const MultiselectFilter = <T extends string>({
    title,
    selectAllLabel,
    initialValues,
    numOptions,
    numAppliedOptions,
    handleApply,
    dataIdPrefix,
    disabled: disabled_ = false,
}: MultiselectFilterProps<T>): ReactElement => {
    const disabled: boolean = disabled_ || numOptions === 0;

    const [filterOpen, setFilterOpen] = useState<boolean>(false);

    const [selectedOptions, setSelectedOptions] = useListState<FilterOption<T>>(initialValues);

    const numCheckedOptions: number = selectedOptions.filter((value) => value.checked).length;
    const areAllChecked: boolean = numCheckedOptions === selectedOptions.length;
    const areSomeChecked: boolean = numCheckedOptions > 0 && !areAllChecked;

    const initialCheckedOptionKeys: Array<T> = getSortedCheckedKeys(initialValues);
    const currentCheckedOptionKeys: Array<T> = getSortedCheckedKeys(selectedOptions);

    const disableApplyButton: boolean =
        currentCheckedOptionKeys.length === 0 ||
        initialCheckedOptionKeys.join() === currentCheckedOptionKeys.join();

    const handleChange = (opened: boolean): void => {
        setSelectedOptions.setState(initialValues);

        setFilterOpen(opened);
    };

    const handleRegionsFilterApplied = (): void => {
        handleApply(areAllChecked ? null : currentCheckedOptionKeys);

        setFilterOpen(!filterOpen);
    };

    const handleSelectAll = (): void => {
        setSelectedOptions.setState(
            selectedOptions.map((option) => ({
                ...option,
                checked: !areAllChecked,
            })),
        );
    };

    const selectedOptionsJsx: Array<ReactElement> = selectedOptions.map((option, index) =>
        optionToReactElement(dataIdPrefix, option, (checked) =>
            setSelectedOptions.setItemProp(index, "checked", checked),
        ),
    );

    return (
        <Popover
            opened={filterOpen}
            onChange={handleChange}
            position="bottom-start"
            closeOnClickOutside
            closeOnEscape
        >
            <Popover.Target>
                <Paper
                    onClick={
                        disabled
                            ? undefined
                            : (): void => {
                                  handleChange(!filterOpen);
                              }
                    }
                    px={12}
                    py={4}
                    bg={disabled ? "flexidaoGrey.1" : "white"}
                    sx={{
                        cursor: disabled ? "not-allowed" : "pointer",
                    }}
                    data-id={labelToDataId({
                        prefix: dataIdPrefix,
                        label: "target",
                    })}
                >
                    <Group position="apart" align="center">
                        <Stack spacing={2}>
                            <Title fz="12px" lh="14px" fw={600}>
                                {title}
                            </Title>
                            <Text fz="12px" lh="14px" size="sm" c="flexidaoGrey.8">
                                {numAppliedOptions ?? numOptions} of {numOptions} selected
                            </Text>
                        </Stack>

                        <IconChevronDown size={16} />
                    </Group>
                </Paper>
            </Popover.Target>

            <Popover.Dropdown
                p={0}
                sx={(theme: MantineTheme): CSSObject => ({
                    border: "none",
                    borderRadius: theme.radius.md,
                })}
            >
                <Paper
                    p={0}
                    sx={{
                        minWidth: "250px",
                        maxWidth: "400px",
                    }}
                    data-id={labelToDataId({
                        prefix: dataIdPrefix,
                        label: "dropdown",
                    })}
                >
                    <Stack spacing={0}>
                        <Checkbox
                            checked={areAllChecked}
                            indeterminate={areSomeChecked}
                            label={selectAllLabel}
                            transitionDuration={0}
                            onChange={handleSelectAll}
                            sx={{
                                fontSize: "14px",
                                fontWeight: 400,
                                lineHeight: "16px",
                                padding: "1rem",
                            }}
                            data-id={labelToDataId({
                                prefix: dataIdPrefix,
                                label: "select-all",
                            })}
                        />

                        <Divider />

                        <Stack
                            spacing={0}
                            pt="xs"
                            pb="xs"
                            sx={{
                                maxHeight: "300px",
                                overflowY: "auto",
                            }}
                        >
                            {selectedOptionsJsx}
                        </Stack>
                    </Stack>

                    <Divider />

                    <Group p="md" position="right">
                        <Button
                            onClick={handleRegionsFilterApplied}
                            disabled={disableApplyButton}
                            data-id={labelToDataId({
                                prefix: dataIdPrefix,
                                label: "apply",
                            })}
                            style={{
                                textTransform: "uppercase",
                            }}
                        >
                            Apply selection
                        </Button>
                    </Group>
                </Paper>
            </Popover.Dropdown>
        </Popover>
    );
};
