import { EacDataDto, eacDataPaths } from "@flexidao/dto";
import * as D from "schemawax";
import { ApiClient } from "../api-client";
import {
    baseEacCodeDecoder,
    baseRegistriesArrayDecoder,
    baseSiteDecoder,
    countryArrayDecoder,
    eacCodeWithRegistryArrayDecoder,
    getAttestationsDecoder,
    getAttestationsFilterOptionsDecoder,
    getProofOfCancellationsDecoder,
    proofOfCancellationTotalsDecoder,
} from "../decoders/eac-data";
import { ApiResponse, ApiResultResponse, isFetchError, OverloadApiResponse } from "../types";
import { buildQueries, crash, routeBlocker } from "../utils";
import axios from "axios";
import { paramsSerializer } from "../utils";
import { voidDecoder } from "@flexidao/helpers";

export type OverloadEacDataClient = {
    // Registries
    getPublicRegistries: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<EacDataDto.BaseRegistry>>;

    // Countries
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<EacDataDto.Country>>;

    // EAC Schemes
    getPublicEacSchemes: (
        getAccessToken: () => Promise<string>,
    ) => OverloadApiResponse<Array<EacDataDto.EacSchemeOption>>;

    // Sites
    getSitesBySiteIdByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        siteId: string;
        tenantId: string;
    }) => OverloadApiResponse<EacDataDto.BaseSite>;

    putSitesSetEacCodeByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        body: EacDataDto.UpdateSiteEacCodePayload;
    }) => OverloadApiResponse<EacDataDto.BaseSite>;

    // EAC Codes
    getEacCodesByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        compatibility?: "site" | "contract";
    }) => OverloadApiResponse<Array<EacDataDto.EacCodeWithRegistry>>;
    putEacCodesByEacCodeIdByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        body: EacDataDto.UpdateEacCodePayload;
        eacCodeId: number;
    }) => OverloadApiResponse<EacDataDto.BaseEacCode>;
    getEacCodesAvailableByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        siteId: string;
        compatibility?: "site" | "contract";
    }) => OverloadApiResponse<Array<EacDataDto.EacCodeWithRegistry>>;
    getEacCodesByEacCodeIdByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        eacCodeId: number;
        tenantId: string;
    }) => OverloadApiResponse<EacDataDto.BaseEacCode>;

    // Cancellations
    getEacProofOfCancellationTotalsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
    }) => OverloadApiResponse<EacDataDto.ProofOfCancellationTotals>;
    getEacProofOfCancellationsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        schemeIdFilter: Array<string> | null;
        registryIdFilter: Array<string> | null;
        productionCountryIdFilter: Array<string> | null;
        energySourceIdFilter: Array<string> | null;
        page: number | null;
        sortField: `${EacDataDto.GetProofOfCancellationsSortField}` | null;
        sortOrder: "ASC" | "DESC" | null;
    }) => OverloadApiResponse<EacDataDto.GetProofOfCancellationsResponse>;

    // Attestations

    getAttestationsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        schemeIdFilter: Array<string> | null;
        productionCountryIdFilter: Array<string> | null;
        energySourceIdFilter: Array<string> | null;
        page: number | null;
        sortField: EacDataDto.GetAttestationsSortField | null;
        sortOrder: "ASC" | "DESC" | null;
    }) => OverloadApiResponse<EacDataDto.GetAttestationsResponse>;

    getAttestationDocumentsByDocumentIdByTenantId: (args: {
        tenantId: string;
        documentId: number;
        getAccessToken: () => Promise<string>;
        downloadFile: (file: Blob, fileName: string) => void;
    }) => OverloadApiResponse<void>;

    getEacDocumentsByDocumentIdByTenantId: (args: {
        tenantId: string;
        documentId: number;
        getAccessToken: () => Promise<string>;
        downloadFile: (file: Blob, fileName: string) => void;
    }) => OverloadApiResponse<void>;

    getAttestationsFilterOptionsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
    }) => OverloadApiResponse<EacDataDto.GetAttestationsFilterOptionsResponse>;

    postEacCsvUploadByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        formData: FormData;
    }) => OverloadApiResponse<void>;
} & ApiClient<eacDataPaths>;

export type EacDataClient = {
    // Registries
    getPublicRegistries: (
        getAccessToken: () => Promise<string>,
    ) => ApiResponse<Array<EacDataDto.BaseRegistry>>;

    // Countries
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => ApiResponse<Array<EacDataDto.Country>>;

    // EAC Schemes
    getPublicEacSchemes: (
        getAccessToken: () => Promise<string>,
    ) => ApiResponse<Array<EacDataDto.EacSchemeOption>>;

    // Sites
    getSitesBySiteIdByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        siteId: string;
        tenantId: string;
    }) => ApiResponse<EacDataDto.BaseSite>;

    putSitesSetEacCodeByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        body: EacDataDto.UpdateSiteEacCodePayload;
    }) => ApiResponse<EacDataDto.BaseSite>;

    // EAC Codes
    getEacCodesByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        compatibility?: "site" | "contract";
    }) => ApiResponse<Array<EacDataDto.EacCodeWithRegistry>>;

    putEacCodesByEacCodeIdByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        body: EacDataDto.UpdateEacCodePayload;
        eacCodeId: number;
    }) => ApiResponse<EacDataDto.BaseEacCode>;

    getEacCodesAvailableByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        siteId: string;
        compatibility?: "site" | "contract";
    }) => ApiResponse<Array<EacDataDto.EacCodeWithRegistry>>;
    getEacCodesByEacCodeIdByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        eacCodeId: number;
        tenantId: string;
    }) => ApiResponse<EacDataDto.BaseEacCode>;

    // Cancellations
    getEacProofOfCancellationTotalsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
    }) => ApiResponse<EacDataDto.ProofOfCancellationTotals>;
    getEacProofOfCancellationsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        schemeIdFilter: Array<string> | null;
        registryIdFilter: Array<string> | null;
        productionCountryIdFilter: Array<string> | null;
        energySourceIdFilter: Array<string> | null;
        page: number | null;
        sortField: `${EacDataDto.GetProofOfCancellationsSortField}` | null;
        sortOrder: "ASC" | "DESC" | null;
    }) => ApiResponse<EacDataDto.GetProofOfCancellationsResponse>;

    // Attestations

    getAttestationsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        schemeIdFilter: Array<string> | null;
        productionCountryIdFilter: Array<string> | null;
        energySourceIdFilter: Array<string> | null;
        page: number | null;
        sortField: EacDataDto.GetAttestationsSortField | null;
        sortOrder: "ASC" | "DESC" | null;
    }) => ApiResponse<EacDataDto.GetAttestationsResponse>;

    getAttestationDocumentsByDocumentIdByTenantId: (args: {
        tenantId: string;
        documentId: number;
        getAccessToken: () => Promise<string>;
        downloadFile: (file: Blob, fileName: string) => void;
    }) => ApiResponse<void>;

    getEacDocumentsByDocumentIdByTenantId: (args: {
        tenantId: string;
        documentId: number;
        getAccessToken: () => Promise<string>;
        downloadFile: (file: Blob, fileName: string) => void;
    }) => ApiResponse<void>;

    getAttestationsFilterOptionsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
    }) => ApiResponse<EacDataDto.GetAttestationsFilterOptionsResponse>;

    postEacCsvUploadByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        formData: FormData;
    }) => ApiResponse<void>;
} & ApiClient<eacDataPaths>;

export type ResultEacDataClient = {
    // Registries
    getPublicRegistries: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<EacDataDto.BaseRegistry>>;

    // Countries
    getPublicCountries: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<EacDataDto.Country>>;

    // EAC Schemes
    getPublicEacSchemes: (
        getAccessToken: () => Promise<string>,
    ) => ApiResultResponse<Array<EacDataDto.EacSchemeOption>>;

    // Sites
    getSitesBySiteIdByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        siteId: string;
        tenantId: string;
    }) => ApiResultResponse<EacDataDto.BaseSite>;

    putSitesSetEacCodeByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        body: EacDataDto.UpdateSiteEacCodePayload;
    }) => ApiResultResponse<EacDataDto.BaseSite>;

    // EAC Codes
    getEacCodesByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        compatibility?: "site" | "contract";
    }) => ApiResultResponse<Array<EacDataDto.EacCodeWithRegistry>>;

    putEacCodesByEacCodeIdByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        body: EacDataDto.UpdateEacCodePayload;
        eacCodeId: number;
    }) => ApiResultResponse<EacDataDto.BaseEacCode>;

    getEacCodesAvailableByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        siteId: string;
        compatibility?: "site" | "contract";
    }) => ApiResultResponse<Array<EacDataDto.EacCodeWithRegistry>>;
    getEacCodesByEacCodeIdByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        eacCodeId: number;
        tenantId: string;
    }) => ApiResultResponse<EacDataDto.BaseEacCode>;

    // Cancellations
    getEacProofOfCancellationTotalsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
    }) => ApiResultResponse<EacDataDto.ProofOfCancellationTotals>;
    getEacProofOfCancellationsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        schemeIdFilter: Array<string> | null;
        registryIdFilter: Array<string> | null;
        productionCountryIdFilter: Array<string> | null;
        energySourceIdFilter: Array<string> | null;
        page: number | null;
        sortField: `${EacDataDto.GetProofOfCancellationsSortField}` | null;
        sortOrder: "ASC" | "DESC" | null;
    }) => ApiResultResponse<EacDataDto.GetProofOfCancellationsResponse>;

    // Attestations

    getAttestationsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
        schemeIdFilter: Array<string> | null;
        productionCountryIdFilter: Array<string> | null;
        energySourceIdFilter: Array<string> | null;
        page: number | null;
        sortField: EacDataDto.GetAttestationsSortField | null;
        sortOrder: "ASC" | "DESC" | null;
    }) => ApiResultResponse<EacDataDto.GetAttestationsResponse>;

    getAttestationDocumentsByDocumentIdByTenantId: (args: {
        tenantId: string;
        documentId: number;
        getAccessToken: () => Promise<string>;
        downloadFile: (file: Blob, fileName: string) => void;
    }) => OverloadApiResponse<void>;

    getEacDocumentsByDocumentIdByTenantId: (args: {
        tenantId: string;
        documentId: number;
        getAccessToken: () => Promise<string>;
    }) => OverloadApiResponse<void>;

    getAttestationsFilterOptionsByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        year: number;
    }) => ApiResultResponse<EacDataDto.GetAttestationsFilterOptionsResponse>;

    postEacCsvUploadByTenantId: (args: {
        getAccessToken: () => Promise<string>;
        tenantId: string;
        formData: FormData;
    }) => ApiResultResponse<void>;
} & ApiClient<eacDataPaths>;

export function getEacDataClient(config: { useRetries?: boolean }): EacDataClient;
export function getEacDataClient(config: { useResult: boolean }): ResultEacDataClient;
export function getEacDataClient(config: {
    useResult?: boolean;
    useRetries?: boolean;
}): Omit<
    OverloadEacDataClient,
    | "getAdminEacProofOfCancellationsByTenantIdSynchronize"
    | "getAdminEacCodesByTenantIdSynchronize"
    | "getAdminAttestationsByTenantIdSynchronize"
    | "getSyncEacTransactionsByTenantId"
    | "getSyncCertificateTransactionsByTenantId"
> {
    let url: string = "";

    let useMockData = false;
    if (typeof window !== "undefined") {
        url = window.location.origin;
    } else if (typeof process !== "undefined") {
        if (process.env.MOCK_EAC_DATA == "true") {
            useMockData = true;
        }
        if (!process.env.EAC_DATA_URL) {
            return crash("Missing EAC_DATA_URL environment variable.");
        } else {
            url = process.env.EAC_DATA_URL;
        }
    } else {
        return crash("No window or process, where the hell are we??!");
    }

    // Axios client for requests that need the response headers
    const axiosClient = axios.create({
        baseURL: url,
        paramsSerializer,
    });

    const { get, put, post } = buildQueries({ ...config, useMockData: useMockData }, url);

    return {
        // Admin
        getAdminProviders: routeBlocker,
        postAdminEacCodes: routeBlocker,
        getAdminEacCodesByTenantAndRegistry: routeBlocker,
        getAdminProvidersByProviderId: routeBlocker,
        postAdminProvidersByProviderIdRateLimit: routeBlocker,
        putAdminProvidersByProviderIdRateLimit: routeBlocker,
        deleteAdminProvidersByProviderIdRateLimit: routeBlocker,
        getAdminTenants: routeBlocker,
        getAdminTenantsByTenantId: routeBlocker,
        getAdminTenantProviders: routeBlocker,
        postAdminTenantProviders: routeBlocker,
        getAdminTenantProvidersByTenant: routeBlocker,
        putAdminTenantProviders: routeBlocker,
        putAdminEventsTrigger: routeBlocker,

        // Tenant
        postAttestationCsvUploadByTenantId: routeBlocker,
        deleteEacProofOfCancellationsByProofOfCancellationIdByTenantId: routeBlocker,
        deleteAttestationsByAttestationIdByTenantId: routeBlocker,
        deleteEacCodesByEacCodeIdByTenantId: routeBlocker,
        getAdminSitesBySiteId: routeBlocker,

        //Local tenants
        putLocalTenantsByTenantId: routeBlocker,

        //EAC transactions
        deleteTransactionsByTransactionIdByTenantId: routeBlocker,
        postEacTransactionsUploadByTenantId: routeBlocker,

        //Balances
        getEacBalancesByTenantId: routeBlocker,

        // Accounts
        getEacAccountsByTenantId: routeBlocker,
        postEacAccountsByTenantId: routeBlocker,
        deleteEacAccountsByTenantId: routeBlocker,

        // Public
        getPublicRegistries: async (
            getAccessToken,
        ): OverloadApiResponse<Array<EacDataDto.BaseRegistry>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/eac-data/public/registries`,
                decoder: baseRegistriesArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getPublicCountries: async (
            getAccessToken,
        ): OverloadApiResponse<Array<EacDataDto.Country>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/eac-data/public/countries`,
                decoder: countryArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // Sites
        getSitesBySiteIdByTenantId: async ({
            getAccessToken,
            siteId,
            tenantId,
        }): OverloadApiResponse<EacDataDto.BaseSite> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/eac-data/${tenantId}/sites/${siteId}`,
                decoder: baseSiteDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        putSitesSetEacCodeByTenantId: async ({
            getAccessToken,
            body,
            tenantId,
        }): OverloadApiResponse<EacDataDto.BaseSite> => {
            const accessToken = await getAccessToken();
            return put({
                path: `/api/eac-data/${tenantId}/sites/set-eac-code`,
                decoder: baseSiteDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: body,
            });
        },

        getEacDocumentsByDocumentIdByTenantId: async ({
            tenantId,
            getAccessToken,
            documentId,
            downloadFile,
        }): ReturnType<OverloadEacDataClient["getEacDocumentsByDocumentIdByTenantId"]> => {
            const accessToken = await getAccessToken();

            const response = await axiosClient.get(
                `/api/eac-data/${tenantId}/eac-documents/${documentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    responseType: "blob",
                },
            );

            if (isFetchError(response.data)) {
                return response.data;
            }
            const fileName = response.headers["content-disposition"].split("filename=")[1];

            downloadFile(response.data, fileName);
        },

        getAttestationDocumentsByDocumentIdByTenantId: async ({
            tenantId,
            getAccessToken,
            documentId,
            downloadFile,
        }): ReturnType<OverloadEacDataClient["getAttestationDocumentsByDocumentIdByTenantId"]> => {
            const accessToken = await getAccessToken();

            const response = await axiosClient.get(
                `/api/eac-data/${tenantId}/attestation-documents/${documentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                    responseType: "blob",
                },
            );

            if (isFetchError(response.data)) {
                return response.data;
            }
            const fileName = response.headers["content-disposition"].split("filename=")[1];

            downloadFile(response.data, fileName);
        },
        // EAC Codes

        getEacCodesByTenantId: async ({
            getAccessToken,
            tenantId,
            compatibility,
        }): OverloadApiResponse<Array<EacDataDto.EacCodeWithRegistry>> => {
            const accessToken = await getAccessToken();
            const query = compatibility ? `?compatibility=${compatibility}` : "";
            return get({
                path: `/api/eac-data/${tenantId}/eac-codes${query}`,
                decoder: eacCodeWithRegistryArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getEacCodesAvailableByTenantId: async ({
            getAccessToken,
            tenantId,
            siteId,
            compatibility,
        }): OverloadApiResponse<Array<EacDataDto.EacCodeWithRegistry>> => {
            const accessToken: string = await getAccessToken();

            return get({
                path: `/api/eac-data/${tenantId}/eac-codes/available`,
                params: {
                    siteId,
                    ...(compatibility !== undefined && { compatibility }),
                },
                decoder: eacCodeWithRegistryArrayDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getEacCodesByEacCodeIdByTenantId: async ({
            getAccessToken,
            tenantId,
            eacCodeId,
        }): OverloadApiResponse<EacDataDto.BaseEacCode> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/eac-data/${tenantId}/eac-codes/${eacCodeId}`,
                decoder: baseEacCodeDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        putEacCodesByEacCodeIdByTenantId: async ({
            getAccessToken,
            tenantId,
            body,
            eacCodeId,
        }): OverloadApiResponse<EacDataDto.BaseEacCode> => {
            const accessToken = await getAccessToken();
            return put({
                path: `/api/eac-data/${tenantId}/eac-codes/${eacCodeId}`,
                decoder: baseEacCodeDecoder,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                data: body,
            });
        },

        getPublicEacSchemes: async (
            getAccessToken,
        ): OverloadApiResponse<Array<EacDataDto.EacSchemeOption>> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/eac-data/public/eac-schemes`,
                decoder: D.array(D.object({ required: { name: D.string, eacSchemeId: D.string } })),
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // Cancellations
        getEacProofOfCancellationTotalsByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
        }): OverloadApiResponse<EacDataDto.ProofOfCancellationTotals> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/eac-data/${tenantId}/eac-proof-of-cancellation-totals`,
                decoder: proofOfCancellationTotalsDecoder,
                params: { year },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getEacProofOfCancellationsByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
            schemeIdFilter,
            registryIdFilter,
            productionCountryIdFilter,
            energySourceIdFilter,
            page,
            sortField,
            sortOrder,
        }): OverloadApiResponse<EacDataDto.GetProofOfCancellationsResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/eac-data/${tenantId}/eac-proof-of-cancellations`,
                decoder: getProofOfCancellationsDecoder,
                params: {
                    year,
                    ...(schemeIdFilter && { schemeIdFilter }),
                    ...(registryIdFilter && { registryIdFilter }),
                    ...(productionCountryIdFilter && { productionCountryIdFilter }),
                    ...(energySourceIdFilter && { energySourceIdFilter }),
                    ...(page && { page }),
                    ...(sortField && { sortField }),
                    ...(sortOrder && { sortOrder }),
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        // Attestations
        getAttestationsByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
            schemeIdFilter,
            productionCountryIdFilter,
            energySourceIdFilter,
            page,
            sortField,
            sortOrder,
        }): OverloadApiResponse<EacDataDto.GetAttestationsResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/eac-data/${tenantId}/attestations`,
                decoder: getAttestationsDecoder,
                params: {
                    year,
                    ...(schemeIdFilter && { schemeIdFilter }),
                    ...(productionCountryIdFilter && { productionCountryIdFilter }),
                    ...(energySourceIdFilter && { energySourceIdFilter }),
                    ...(page && { page }),
                    ...(sortField && { sortField }),
                    ...(sortOrder && { sortOrder }),
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
        getAttestationsFilterOptionsByTenantId: async ({
            getAccessToken,
            tenantId,
            year,
        }): OverloadApiResponse<EacDataDto.GetAttestationsFilterOptionsResponse> => {
            const accessToken = await getAccessToken();
            return get({
                path: `/api/eac-data/${tenantId}/attestations-filter-options`,
                decoder: getAttestationsFilterOptionsDecoder,
                params: {
                    year,
                },
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },

        postEacCsvUploadByTenantId: async ({
            getAccessToken,
            tenantId,
            formData,
        }): OverloadApiResponse<void> => {
            const accessToken = await getAccessToken();

            return post({
                path: `/api/eac-data/${tenantId}/eac-csv-upload`,
                decoder: voidDecoder,
                data: formData,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        },
    };
}
