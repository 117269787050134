import {
    ExpectedIntervalReadingType,
    ExpectedReadingGranularity,
    MeterReadingTypeEnum,
    ProvidersEnum,
    SiteType,
} from "./enum";
import { meterDataComponents, meterDataPaths } from "./index";

export type IKind<T> = {
    kind: T;
};
export namespace MeterDataDto {
    export type SyncResponse<T> = {
        data: Array<T>;
        next_cursor: string | null;
        has_more: boolean;
    };

    export type Tenant = {
        tenantId: string;
        name?: string;
    };

    // export type TenantFull = Tenant & {
    //     version: Date;
    // };

    export type TenantProvider = {
        tenantId: string;
        providerId: ProvidersEnum;
        enabled?: boolean | null;
    };

    enum GoogleBigQueryMeterType {
        IN = 1,
        OUT = 2,
    }

    export enum GoogleBigQueryGranularity {
        HOUR = "hour",
        QUARTER = "quarter",
    }

    export enum GoogleBigQueryEnergyField {
        V = "v",
        KW = "kw",
    }

    export type NoMeterExtraData = IKind<"NoExtraData">;

    type ShareOfEnergyAtProvider = {
        shareOfEnergyAtProvider?: number;
    };

    export type DatadisExtra = {
        distributorCode: string;
        pointType: number;
    } & IKind<"DatadisExtra">;

    export type BigqueryMeterExtra = {
        rowkey: string;
        dataPoint: string;
        type: GoogleBigQueryMeterType;
        energyField: GoogleBigQueryEnergyField.V | GoogleBigQueryEnergyField.KW;
        granularity: GoogleBigQueryGranularity.QUARTER | GoogleBigQueryGranularity.HOUR;
        shareOfEnergyAtProvider?: number;
    } & IKind<"BigQueryExtra">;

    export type CSVExtraData = {
        shareOfEnergyAtProvider?: number;
        originalProvider?: string;
    } & IKind<"CSVExtraData">;

    export type WattsonExtraData = {
        country: string;
        type: MeterReadingTypeEnum.IntervalConsumption | MeterReadingTypeEnum.IntervalProduction;
    } & IKind<"WattsonExtra">;

    export type CesMeterExtra = {
        fileNamePrefix: string;
        dateFormat: string;
        extension: ".xlsx" | ".csv";
        columns: "RT" | "MW";
        subdirectory?: Array<string>;
        productionDir: "+" | "-";
    } & IKind<"CesExtra">;

    export type MidAmericanMeterExtra = {
        accountId: string;
        timezone: string;
    } & IKind<"MidAmericanExtra">;

    export type ApiUploadExtra = {
        serviceAccounts: Array<string>;
        originalProvider?: string;
    } & IKind<"ApiUploadExtra">;

    export type UtilityApiExtra = {
        originalProvider?: string;
    } & IKind<"UtilityAPIExtra">;

    export type TenaskaPowerServiceExtra = {
        market: string;
        dataPoint: string;
    } & IKind<"TenaskaPowerServiceExtra">;

    export type EnecoMeterExtra = {
        fileNamePrefix: string;
        fileNameDateFormat: string;
        fileNameExtension: "csv";
    } & IKind<"EnecoMeterExtra">;

    export type EddieMeterExtra = {
        customerConnectionId: string;
    } & IKind<"EddieMeterExtra">;

    export type ElexonMeterExtra = {
        shareOfEnergyAtProvider?: number;
    } & IKind<"ElexonMeterExtra">;

    export type SemoMeterExtra = {
        participantName: string;
        resourceName: string;
    } & ShareOfEnergyAtProvider &
        IKind<"SemoMeterExtra">;

    export type ModityMeterExtra = {
        measurandId: string;
    } & ShareOfEnergyAtProvider &
        IKind<"ModityMeterExtra">;

    export type NWCPUDMeterExtra = {
        meterReadingId: number;
    } & IKind<"NWCPUDMeterExtra">;

    export type MeterExtraData =
        | NoMeterExtraData
        | BigqueryMeterExtra
        | CSVExtraData
        | ElexonMeterExtra
        | WattsonExtraData
        | DatadisExtra
        | CesMeterExtra
        | EnecoMeterExtra
        | EddieMeterExtra
        | MidAmericanMeterExtra
        | TenaskaPowerServiceExtra
        | ApiUploadExtra
        | SemoMeterExtra
        | ModityMeterExtra
        | UtilityApiExtra
        | NWCPUDMeterExtra;

    export type Meter = {
        meterId: number;
        meterProviderCode: string;
        tenantId: string;
        providerId: ProvidersEnum;
        enabled: boolean;
        initialTimeToCheckUtc: string;
        extra: MeterExtraData;
        siteId?: string | null;
        expectedIntervalReadingType: ExpectedIntervalReadingType;
        expectedReadingGranularity: ExpectedReadingGranularity;
        endTimeToCheckUtc?: string | null;
        expectedDelayDays?: number | null;
    };

    export type MeterOption = meterDataComponents["schemas"]["MeterOption"];

    /** Meter without extra data */
    export type BasicMeter = {
        meterId: number;
        meterProviderCode: string;
        tenantId: string;
        providerId: ProvidersEnum;
        enabled: boolean;
        initialTimeToCheckUtc: Date;
        siteId: string | null;
        endTimeToCheckUtc: Date | null;
        expectedIntervalReadingType: ExpectedIntervalReadingType;
        expectedReadingGranularity: ExpectedReadingGranularity;
        expectedDelayDays: number | null;
    };

    export type MeterCreation = {
        meterProviderCode: string;
        tenantId: string;
        providerId: ProvidersEnum;
        enabled?: boolean | null;
        initialTimeToCheckUtc: Date;
        expectedIntervalReadingType?: ExpectedIntervalReadingType;
        expectedReadingGranularity?: ExpectedReadingGranularity;
        expectedDelayDays?: number | null;
        extra: {
            kind: string;
        };
        endTimeToCheckUtc?: Date;
        siteName?: string;
        shareOfEnergyAtProvider: number;
    };

    export type PutSiteMetersBySiteIdPayload = {
        meterIds: Array<number>;
    };

    export type SiteMeterReading = {
        startTime: Date;
        endTime: Date;
        siteId: string;
        type: MeterReadingTypeEnum;
        quantityWh: number | null;
        //status: string;
        created: Date;
        updated: Date;
        warnings?: Array<string> | null;
        childWarnings?: Array<string> | null;
        outlier: boolean;
        //deleted: Date;
    };

    export type MeterDataSyncResponse = SyncResponse<SiteMeterReading>;

    export type IntervalMeter = meterDataComponents["schemas"]["IntervalMeter"];

    export type GetIntervalMetersResponse =
        meterDataComponents["schemas"]["GetIntervalMetersResponse"];

    export type GetIntervalMetersFiltersResponse =
        meterDataComponents["schemas"]["GetIntervalMetersFiltersResponse"];

    export type GetIntervalMetersPayload =
        meterDataPaths["/{tenantId}/interval-meters"]["get"]["parameters"]["query"] &
            meterDataPaths["/{tenantId}/interval-meters"]["get"]["parameters"]["path"];

    export type GetIntervalMetersFiltersPayload =
        meterDataPaths["/{tenantId}/interval-meters-filters"]["get"]["parameters"]["path"];

    export type BillingMeter = meterDataComponents["schemas"]["BillingMeter"];

    export type GetBillingMetersResponse =
        meterDataComponents["schemas"]["GetBillingMetersResponse"];

    export type GetBillingMetersFiltersResponse =
        meterDataComponents["schemas"]["GetBillingMetersFiltersResponse"];

    export type GetBillingMetersPayload =
        meterDataPaths["/{tenantId}/billing-meters"]["get"]["parameters"]["query"] &
            meterDataPaths["/{tenantId}/billing-meters"]["get"]["parameters"]["path"];

    export type GetBillingMetersFiltersPayload =
        meterDataPaths["/{tenantId}/billing-meters-filters"]["get"]["parameters"]["path"];

    export type GetMeterOptionsPayload =
        meterDataPaths["/{tenantId}/meter-options"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/meter-options"]["get"]["parameters"]["query"];

    export type GetWarningTypeOptionsPayload =
        meterDataPaths["/{tenantId}/warning-type-options"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/warning-type-options"]["get"]["parameters"]["query"];

    export type GetMonitoringSiteDetailsKpisByTenantIdPayload =
        meterDataPaths["/{tenantId}/monitoring-site-details-kpis"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/monitoring-site-details-kpis"]["get"]["parameters"]["query"];

    export type GetAggregatedWarningsPerSitePayload =
        meterDataPaths["/{tenantId}/aggregated-warnings-per-site"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/aggregated-warnings-per-site"]["get"]["parameters"]["query"];

    export type MonitoringSiteDetailsKpis =
        meterDataPaths["/{tenantId}/monitoring-site-details-kpis"]["get"]["responses"]["200"]["content"]["application/json"];

    export type SiteLevelWarning = meterDataComponents["schemas"]["SiteLevelWarning"];
    export type MeterLevelWarningsBreakdown =
        meterDataComponents["schemas"]["MeterLevelWarningsBreakdown"];
    export type SiteLevelWarningsBreakdown =
        meterDataComponents["schemas"]["SiteLevelWarningsBreakdown"];

    export type Credential = {
        name: string;
        description?: string | null;
        secret: string | Record<string, never>;
        expirationDate?: Date;
    };

    export type TotalConsumptionAndProductionKpi = {
        totalConsumptionWh: number | null;
        totalProductionWh: number | null;
    };

    export type ActiveSitesKpi = {
        totalActiveSites: number;
        activeConsumptionSites: number;
        activeProductionSites: number;
    };

    export type RegionOption = {
        regionId: string;
        regionName: string;
    };

    export type BiddingZoneOption = {
        biddingZoneId: string;
        biddingZoneName: string;
    };

    export type SiteOption = {
        siteId: string;
        siteName: string;
        regionName: string;
        biddingZoneName: string;
        timezone: string;
    };

    export type ProviderOption = {
        providerId: string;
        providerName: string;
    };

    export type MeterDataMeterBreakdownRow = {
        meterCode: string;
        consumptionWh: number | null;
        productionWh: number | null;
        numReadings: number;
        expectedNumReadings: number;
        dataCompleteness: number;
        dataProvider: string;
        lastDataUpdate: Date | null;
        dateOfFirstReading: Date | null;
        dateOfLastReading: Date | null;
        currentDelayDays: number | null;
        expectedDelayDays: number | null;
        initialTimeToCheckLocal: Date;
        endTimeToCheckLocal: Date | null;
    };

    export type MeterDataSiteBreakdownRow = {
        siteName: string;
        siteType: SiteType;
        energySource: string | null;
        region: string;
        biddingZone: string;
        timeZone: string;
        consumptionWh: number | null;
        productionWh: number | null;
        numReadings: number;
        expectedNumReadings: number;
        dataCompleteness: number;
        dataProvider: string;
        lastDataUpdate: Date | null;
        dateOfFirstReading: Date | null;
        dateOfLastReading: Date | null;
        currentDelayDays: number | null;
        expectedDelayDays: number | null;
        dataPerMeter?: Array<MeterDataMeterBreakdownRow> | null;
    };

    export type MeterDataSiteBreakdown = {
        rowCount: number;
        rows: Array<MeterDataSiteBreakdownRow>;
    };

    export type HourlyAggregatedMeterData = {
        startTime: Date;
        consumptionWh: number | null;
        productionWh: number | null;
    };

    export type DataCompleteness = {
        dataCompletenessRatio: number;
    };

    export type AggregatedWarningsPerSite =
        meterDataComponents["schemas"]["AggregatedWarningsPerSite"];

    export type GetHourlyMeterWarningsPayload =
        meterDataPaths["/{tenantId}/hourly-meter-warnings"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/hourly-meter-warnings"]["get"]["parameters"]["query"];
    export type SiteHourlyMeterWarnings = meterDataComponents["schemas"]["SiteHourlyMeterWarnings"];

    export type GetHourlySiteWarningsPayload =
        meterDataPaths["/{tenantId}/hourly-site-warnings"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/hourly-site-warnings"]["get"]["parameters"]["query"];
    export type SiteHourlySiteWarnings = meterDataComponents["schemas"]["SiteHourlySiteWarnings"];

    export type ConsumptionBySite = {
        page: Array<{
            siteId: string;
            siteName: string;
            countryName: string;
            consumptionWh: number;
        }>;
        totalSites: number;
    };

    export type File = {
        fileId: string;
        tenantId: string;
        userId?: string;
        executionId?: string;
        created: Date;
    };
}

// TODO change it
export namespace ProviderDto {
    type BaseRateLimit = {
        group?: string;
    };

    export type RateLimitEnum =
        | "RateLimitOnSeconds"
        | "RateLimitOnMinutes"
        | "RateLimitOnHours"
        | "RateLimitOnDays"
        | "NoRateLimit";

    export type RateLimitOnSeconds = {
        seconds: number;
        limit: number;
    } & IKind<"RateLimitOnSeconds"> &
        BaseRateLimit;

    export type RateLimitOnMinutes = {
        minutes: number;
        limit: number;
    } & IKind<"RateLimitOnMinutes"> &
        BaseRateLimit;
    export type RateLimitOnHours = {
        hours: number;
        limit: number;
    } & IKind<"RateLimitOnHours"> &
        BaseRateLimit;
    export type RateLimitOnDays = {
        days: number;
        limit: number;
    } & IKind<"RateLimitOnDays"> &
        BaseRateLimit;
    export type NoRateLimit = IKind<"NoRateLimit"> & BaseRateLimit;

    export type RateLimit =
        | NoRateLimit
        | RateLimitOnSeconds
        | RateLimitOnMinutes
        | RateLimitOnHours
        | RateLimitOnDays;

    type CredentialsLevel = "Flexidao" | "TenantProvider" | "Meter" | "NoCredentials";

    export type Provider = {
        providerId: ProvidersEnum;
        recheckAvailable: boolean;
        meterDiscovery: boolean;
        credentialLevel: Array<CredentialsLevel>;
        rateLimits: Array<RateLimit>;
    };
}
