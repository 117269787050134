import { CfeInventoryPage, Module } from "@flexidao/dto";
import { APP_PREFIX } from "./consts";

const basePath = `/${APP_PREFIX}/${Module.CfeInventory}`;

type CfeInventoryPaths = {
    [CfeInventoryPage.TwentyFourSevenCfeDashboard]: string;
    [CfeInventoryPage.PpaFinancialDashboard]: string;
    [CfeInventoryPage.ContractTracking]: string;
    [CfeInventoryPage.TransactionsAllocation]: string;
    [CfeInventoryPage.ReportingDashboard]: string;
    [CfeInventoryPage.ReportingDashboardCountry]: string;
    [CfeInventoryPage.AssignmentOverview]: (args: {
        year: string;
        consumptionSiteGroupId?: string;
    }) => string;
    [CfeInventoryPage.BiddingZoneAssignment]: (args: {
        year: string;
        biddingZoneId: string;
        consumptionSiteGroupId?: string;
    }) => string;
    [CfeInventoryPage.EditBiddingZoneAssignment]: (args: {
        year: string;
        biddingZoneId: string;
        consumptionSiteGroupId?: string;
    }) => string;
    [CfeInventoryPage.GlobalOverview]: string;
};

export const cfeInventoryPaths: CfeInventoryPaths = {
    // 24/7
    [CfeInventoryPage.TwentyFourSevenCfeDashboard]: `${basePath}/${CfeInventoryPage.TwentyFourSevenCfeDashboard}`,
    [CfeInventoryPage.PpaFinancialDashboard]: `${basePath}/${CfeInventoryPage.PpaFinancialDashboard}`,

    // Report Hub
    [CfeInventoryPage.ContractTracking]: `${basePath}/${CfeInventoryPage.ContractTracking}`,
    [CfeInventoryPage.TransactionsAllocation]: `${basePath}/${CfeInventoryPage.TransactionsAllocation}`,
    [CfeInventoryPage.ReportingDashboard]: `${basePath}/${CfeInventoryPage.ReportingDashboard}`,
    [CfeInventoryPage.ReportingDashboardCountry]: `${basePath}/${CfeInventoryPage.ReportingDashboardCountry}`,
    [CfeInventoryPage.AssignmentOverview]: ({ year, consumptionSiteGroupId }) =>
        `${basePath}/${CfeInventoryPage.AssignmentOverview}/${year}${
            consumptionSiteGroupId ? `?consumptionSiteGroupId=${consumptionSiteGroupId}` : ""
        }`,
    [CfeInventoryPage.BiddingZoneAssignment]: ({ year, biddingZoneId, consumptionSiteGroupId }) =>
        `${basePath}/${CfeInventoryPage.BiddingZoneAssignment}/${year}/${biddingZoneId}${
            consumptionSiteGroupId ? `?consumptionSiteGroupId=${consumptionSiteGroupId}` : ""
        }`,
    [CfeInventoryPage.EditBiddingZoneAssignment]: ({
        year,
        biddingZoneId,
        consumptionSiteGroupId,
    }): string =>
        `${basePath}/${CfeInventoryPage.EditBiddingZoneAssignment}/${year}/${biddingZoneId}${
            consumptionSiteGroupId ? `?consumptionSiteGroupId=${consumptionSiteGroupId}` : ""
        }`,
    [CfeInventoryPage.GlobalOverview]: `${basePath}/${CfeInventoryPage.GlobalOverview}`,
};
