export const formatYear = (date: Date, timeZone?: string): string =>
    date.toLocaleDateString("en-GB", {
        year: "numeric",
        timeZone,
    });

export const formatMonth = (date: Date, timeZone?: string): string =>
    date.toLocaleDateString("en-GB", {
        month: "short",
        year: "numeric",
        timeZone,
    });

export const formatDate = (date: Date, timeZone?: string): string =>
    date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        timeZone,
    });

export const formatDateMonthYear = (date: Date): string =>
    date.toLocaleDateString("en-US", {
        month: "short",
        year: "numeric",
    });

export const formatDateMonth = (date: Date, timeZone?: string): string =>
    date.toLocaleDateString("en-GB", {
        month: "short",
        year: "numeric",
        timeZone,
    });

export const formatDateTime = (date: Date, timeZone?: string): string =>
    `${date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        timeZone,
    })} h`;

export const formatDateHourMinute = (date: Date, timeZone?: string): string =>
    `${date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone,
    })} h`;

export const formatDateHour = (date: Date, timeZone?: string): string =>
    `${date.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        timeZone,
    })} h`;

export const formatYearOrDash = (date: Date | null, timeZone?: string): string => {
    if (!date) {
        return "-";
    }

    return formatYear(date, timeZone);
};

export const formatDateMonthYearOrUnknown = (date: Date | null, timeZone?: string): string => {
    if (!date) {
        return "Unknown";
    }

    return formatDate(date, timeZone);
};
