import axios, {
    AxiosInstance,
    AxiosResponse,
    ParamsSerializerOptions,
    RawAxiosRequestHeaders,
} from "axios";
import axiosRetry from "axios-retry";
import qs from "qs";
import * as D from "schemawax";
import { Err, Ok } from "ts-results";
import { mockAxiosClient } from "./mocks";
import { ApiResponse, ApiResultResponse, FetchError, OverloadApiResponse } from "./types";

// function makeDate(timestamp: number): Date;
// function makeDate(m: number, d: number, y: number): Date;
type PrimitiveTypes = string | number | boolean | null | undefined;
type PrimitiveTypesWithArray = PrimitiveTypes | Array<PrimitiveTypes>;
type ReadonlyOrNot<T> = Readonly<T> | T;

type GetParams<T> = {
    path: string;
    decoder: D.Decoder<T>;
    params?: ReadonlyOrNot<
        Record<
            string,
            | ReadonlyOrNot<PrimitiveTypesWithArray>
            | Record<string, ReadonlyOrNot<PrimitiveTypesWithArray>>
        >
    >;
    headers?: RawAxiosRequestHeaders;
};

type DelParams<T> = {
    path: string;
    decoder: D.Decoder<T>;
    params?: Record<string, string | Array<string> | number | Array<number>>;
    headers?: RawAxiosRequestHeaders;
};

type PostParams<T, P> = {
    path: string;
    decoder: D.Decoder<T>;
    data: P;
    params?: Record<string, string | Array<string> | number | Array<number>>;
    headers?: RawAxiosRequestHeaders;
};

type PutParams<T, P> = {
    path: string;
    decoder: D.Decoder<T>;
    data: P;
    params?: Record<string, string | Array<string> | number | Array<number>>;
    headers?: RawAxiosRequestHeaders;
};
export const paramsSerializer: ParamsSerializerOptions = {
    serialize: (params: Record<string, string>) =>
        qs.stringify(params, { arrayFormat: "brackets", allowEmptyArrays: true }),
};
export function buildQueries(
    config: { useMockData: boolean; useResult: boolean },
    baseUrl: string,
): {
    get: <T>(params: GetParams<T>) => ApiResultResponse<T>;
    del: <T>(params: DelParams<T>) => ApiResultResponse<T>;
    post: <T, P>(params: PostParams<T, P>) => ApiResultResponse<T>;
    put: <T, P>(params: PutParams<T, P>) => ApiResultResponse<T>;
    patch: <T, P>(params: PutParams<T, P>) => ApiResultResponse<T>;
};
export function buildQueries(
    config: { useMockData: boolean },
    baseUrl: string,
): {
    get: <T>(params: GetParams<T>) => ApiResponse<T>;
    del: <T>(params: DelParams<T>) => ApiResponse<T>;
    post: <T, P>(params: PostParams<T, P>) => ApiResponse<T>;
    put: <T, P>(params: PutParams<T, P>) => ApiResponse<T>;
    patch: <T, P>(params: PutParams<T, P>) => ApiResponse<T>;
};
export function buildQueries(
    {
        useMockData,
        useResult,
        useRetries,
    }: { useMockData: boolean; useResult?: boolean; useRetries?: boolean },
    baseUrl: string,
): {
    get: <T>(params: GetParams<T>) => OverloadApiResponse<T>;
    del: <T>(params: DelParams<T>) => OverloadApiResponse<T>;
    post: <T, P>(params: PostParams<T, P>) => OverloadApiResponse<T>;
    put: <T, P>(params: PutParams<T, P>) => OverloadApiResponse<T>;
    patch: <T, P>(params: PutParams<T, P>) => OverloadApiResponse<T>;
} {
    const axiosClient = useMockData
        ? (mockAxiosClient as AxiosInstance)
        : axios.create({
              baseURL: baseUrl,
              paramsSerializer,
          });

    if (useRetries && useRetries === true) {
        // Add auto retries to API Client requests
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore axiosRetry is not typed correctly
        axiosRetry(axiosClient, {
            retries: 5,
            // 1sec then 2 then 3 etc
            retryDelay: (retryCount) => retryCount * 1000,
            // retry on network errors or 5xx status codes
            retryCondition: (error) =>
                error.code === "ECONNABORTED" ||
                error.code === "ETIMEDOUT" ||
                (error.response?.status ?? 0) >= 500,
        });
    }

    const handleClientError = (e: unknown, path: string): FetchError | Err<FetchError> => {
        if (e instanceof axios.AxiosError) {
            const err: FetchError = { kind: "http_error", path, payload: e };
            return useResult ? Err(err) : err;
        } else {
            const err: FetchError = {
                kind: "unhandled_error",
                path,
                payload: errorFromUnknown(e),
            };
            return useResult ? Err(err) : err;
        }
    };
    function handleClientResponse<T>(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        { data }: AxiosResponse<any, any>,
        decoder: D.Decoder<T>,
        path: string,
    ): T | Ok<T> | Err<FetchError> | FetchError {
        const validatedResult = decoder.validate(data);

        if (validatedResult.type === "error") {
            const err: FetchError = {
                kind: "decode_error",
                path,
                payload: addDataToDecoderError(validatedResult.error, data),
            };
            return useResult ? Err(err) : err;
        }
        // Everything is ok, return the raw JSON.
        const response = validatedResult.data;
        return useResult ? Ok(response) : response;
    }
    return {
        get: async <T>({
            path,
            decoder,
            headers,
            params,
        }: GetParams<T>): OverloadApiResponse<T> => {
            try {
                const result = await axiosClient.get(path, { params, headers });

                return handleClientResponse(result, decoder, path);
            } catch (e) {
                return handleClientError(e, path);
            }
        },
        del: async <T>({
            path,
            decoder,
            headers,
            params,
        }: DelParams<T>): OverloadApiResponse<T> => {
            try {
                const result = await axiosClient.delete(path, { params, headers });
                return handleClientResponse(result, decoder, path);
            } catch (e) {
                return handleClientError(e, path);
            }
        },
        post: async <T, P>({
            path,
            decoder,
            headers,
            params,
            data,
        }: PostParams<T, P>): OverloadApiResponse<T> => {
            try {
                const result = await axiosClient.post(path, data, { params, headers });
                return handleClientResponse(result, decoder, path);
            } catch (e) {
                return handleClientError(e, path);
            }
        },
        put: async <T, P>({
            path,
            decoder,
            headers,
            params,
            data,
        }: PostParams<T, P>): OverloadApiResponse<T> => {
            try {
                const result = await axiosClient.put(path, data, { params, headers });
                return handleClientResponse(result, decoder, path);
            } catch (e) {
                return handleClientError(e, path);
            }
        },
        patch: async <T, P>({
            path,
            decoder,
            headers,
            params,
            data,
        }: PostParams<T, P>): OverloadApiResponse<T> => {
            try {
                const result = await axiosClient.patch(path, data, { params, headers });
                return handleClientResponse(result, decoder, path);
            } catch (e) {
                return handleClientError(e, path);
            }
        },
    };
}

export const crash = <T>(msg: string): T => {
    throw new Error(msg);
};

export const iso8601TimestampParser = (value: string): Date => {
    const isoDateRegex = new RegExp(
        /^\d{4}-(0[1-9]|1[0-2])-([0-2]\d|3[01])T([01]\d|2[0-3]):([0-5]\d):([0-5]\d)(.\d{3})?(Z|[+-]\d{2}(:[0-5]\d)?)$/gm,
    );
    if (!isoDateRegex.test(value)) {
        throw new Error(
            `Provided ISO 8601 timestamp '${value}' is not valid. It should follow ISO 8601 timestamp format.`,
        );
    }

    const date: Date = new Date(value);
    if (date == null || isNaN(date.getTime())) {
        throw new Error(`Invalid date '${value}'.`);
    }

    if (Math.abs(date.getTime()) >= 1_000_000_000_000_000) {
        throw new Error(
            `Parsed date '${date.toISOString()}' is not valid. It is too far away in time.`,
        );
    }

    return date;
};

export const isoTimestampDecoder: D.Decoder<Date> = D.string.andThen((value) => {
    return iso8601TimestampParser(value);
});

export const addDataToDecoderError = (
    decodeError: D.DecoderError,
    data: unknown,
): D.DecoderError & { data: unknown } => {
    return {
        name: decodeError.name,
        message: decodeError.message,
        stack: decodeError.stack,
        path: decodeError.path,
        data,
    };
};

/**
 * Convert an unknown error from a catch block to an instance of Error
 * @param error an error of type `unknown` from a catch block
 * @returns a `new Error` object
 */
const errorFromUnknown = (error: unknown): Error => {
    if (error instanceof Error) {
        return error;
    }
    return new Error(String(error));
};

/**
 * A handler for admin routes that should not be used in the API client
 * @returns a rejected promise with a message that this route is not for use in the API client
 */
export const routeBlocker = async (): Promise<never> =>
    Promise.reject("Route not for use in API client or inter service comms");
