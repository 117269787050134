import { IconWithTooltip, formatNumber, labelToDataId } from "@flexidao/ui-lib";
import { Group, Skeleton, Stack, Text, Title } from "@mantine/core";
import { ReactElement } from "react";
import { TooltipType } from "@flexidao/ui-lib/components/overlays/icon-with-tooltip/types";
import { ComponentState } from "@flexidao/dto";

export type KpiSectionLargeProps = {
    label: string;
    value: number | null;
    numDecimals: number;
    unit: string | null;
    tooltip?: TooltipType;
    dataId?: string;
    showSkeleton?: boolean; // This will be removed later on
    state?: ComponentState; // This will be made mandatory later on
};

export const KpiSectionLarge = ({
    label,
    value,
    numDecimals,
    unit,
    tooltip,
    dataId,
    showSkeleton,
    state,
}: KpiSectionLargeProps): ReactElement => {
    return (
        <Stack spacing={0} px="xl" py={0} data-id={dataId}>
            <Group spacing={4}>
                <Title order={2} weight={600} size="14px" lh="16px">
                    {label}
                </Title>
                {tooltip && (
                    <IconWithTooltip
                        tooltip={tooltip}
                        data-id={labelToDataId({ prefix: dataId, label: "tooltip-content" })}
                    />
                )}
            </Group>

            {getDisplayValue({ showSkeleton, state, value, numDecimals, unit })}
        </Stack>
    );
};

const getDisplayValue = ({
    showSkeleton,
    state,
    value,
    numDecimals,
    unit,
}: {
    showSkeleton?: boolean;
    state?: ComponentState;
    value: number | null;
    numDecimals: number;
    unit: string | null;
}): ReactElement => {
    if (showSkeleton || state === ComponentState.Loading) {
        return <Skeleton height={30} width={200} />;
    }

    if (state === ComponentState.Error) {
        return (
            <Text size="xs" color="flexidaoRed">
                Error
            </Text>
        );
    }

    const valueString: string = formatNumber(value, numDecimals);
    const valueSplitArray: Array<string> = valueString.split(".");

    const integerPart: string = valueSplitArray[0]!;
    const decimalPart: string | null = valueSplitArray[1] ?? null;

    const unitSize = unit === "%" ? "18px" : "10px";
    const unitLineHeight = unit === "%" ? "23px" : "12px";

    return (
        <Text>
            <Text span>
                <Text span weight="bold" ff="Lexend Deca" size="24px" lh="30px">
                    {integerPart}
                </Text>

                {decimalPart !== null && (
                    <Text span weight="bold" ff="Lexend Deca" size="18px" lh="23px">
                        .{decimalPart}
                    </Text>
                )}
            </Text>

            {value !== null && unit !== null && (
                <>
                    {" "}
                    <Text span weight="bold" size={unitSize} lh={unitLineHeight}>
                        {unit}
                    </Text>
                </>
            )}
        </Text>
    );
};
