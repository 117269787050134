import { BimDto } from ".";

export enum ComponentState {
    Loading = "loading",
    Error = "error",
    Success = "success",
}

export enum EnergySource {
    Biomass = "biomass",
    Coal = "coal",
    Gas = "gas",
    Geothermal = "geothermal",
    Hydro = "hydro",
    Nuclear = "nuclear",
    Oil = "oil",
    Other = "other",
    Solar = "solar",
    Wind = "wind",
    RenewableUnknown = "renewable-unknown",
}

export const validEacEnergySources: Array<EnergySource> = [
    EnergySource.Hydro,
    EnergySource.Nuclear,
    EnergySource.Solar,
    EnergySource.Wind,
    EnergySource.Biomass,
];

export enum IndustryType {
    Automotive = "automotive",
    ConsumerGoods = "cgoods",
    DataCenters = "dcenters",
    Education = "education",
    FinancialServices = "financial",
    Healthcare = "healthcare",
    Manufacturing = "manufacturing",
    Office = "office",
    Pharma = "pharma",
    RealState = "restate",
    Retail = "retail",
    Telecoms = "telecomms",
}

export enum ContractType {
    VPPA = "vppa",
    PPA = "ppa",
    GreenTariff = "green_tariff",
    UnbundledEACs = "unbundled_eacs",
}

export enum CfeDiagnosticContractType {
    PPA = "ppa",
    GreenTariff = "green_tariff",
    UnbundledEACs = "unbundled_eacs",
    NonCfe = "non_cfe",
}

export enum ContractStartDate {
    Before2024 = "before-january-2024",
    After2024 = "after-january-2024",
}
export enum AssetCommissioningDate {
    Unknown = "unknown",
    Less15YearsAgo = "less-than-15-years-ago",
    EqualOrMore15YearsAgo = "15-years-ago-or-more",
}

export enum EstimationStatus {
    Pending = "pending",
    Processing = "processing",
    Ready = "ready",
}

export enum Module {
    CfeConnect = "cfe-connect",
    CfeDiagnostic = "cfe-diagnostic",
    CfeInventory = "cfe-inventory",
    Settings = "settings",
}

export const isModule = (m: unknown): m is Module => Object.values(Module).some((x) => x === m);

// CFE CONNECT

export enum CfeConnectPage {
    MeterData = "meter-data",
    Monitoring = "monitoring",
    MonitoringSiteDetails = "monitoring-site-details",
    EacData = "eac-data",
}

export type CfeConnectNavPage =
    | CfeConnectPage.MeterData
    | CfeConnectPage.Monitoring
    | CfeConnectPage.EacData;

export const isCfeConnectPage = (p: unknown): p is CfeConnectPage =>
    Object.values(CfeConnectPage).some((v) => v === p);

export const isCfeConnectNavPage = (p: unknown): p is CfeConnectNavPage =>
    [CfeConnectPage.MeterData, CfeConnectPage.Monitoring, CfeConnectPage.EacData].some(
        (v) => v === p,
    );

// CFE DIAGNOSTIC
export enum CfeDiagnosticPage {
    Estimations = "estimations",
    NewEstimation = "new-estimation",
    EditEstimation = "edit-estimation",
    EstimationResult = "estimation-result",
}

export const isCfeDiagnosticPage = (p: unknown): p is CfeDiagnosticPage =>
    Object.values(CfeDiagnosticPage).some((v) => v === p);

export type CfeDiagnosticNavPage = CfeDiagnosticPage.Estimations;

export const isCfeDiagnosticNavPage = (p: unknown): p is CfeDiagnosticNavPage =>
    [CfeDiagnosticPage.Estimations].some((v) => v === p);

// CFE INVENTORY
export enum CfeInventoryPage {
    TwentyFourSevenCfeDashboard = "24-7-cfe-dashboard",
    PpaFinancialDashboard = "ppa-financial-dashboard",
    ContractTracking = "contract-tracking",
    TransactionsAllocation = "transactions-allocation",
    ReportingDashboard = "reporting-dashboard",
    ReportingDashboardCountry = "reporting-dashboard-country",
    AssignmentOverview = "assignment-overview",
    BiddingZoneAssignment = "bidding-zone-assignment",
    EditBiddingZoneAssignment = "edit-bidding-zone-assignment",
    GlobalOverview = "global-overview",
}

export type CfeInventoryNavPage =
    | CfeInventoryPage.TwentyFourSevenCfeDashboard
    | CfeInventoryPage.PpaFinancialDashboard
    | CfeInventoryPage.ReportingDashboard
    | CfeInventoryPage.ContractTracking
    | CfeInventoryPage.GlobalOverview;

export const isCfeInventoryPage = (p: unknown): p is CfeInventoryPage =>
    Object.values(CfeInventoryPage).some((v) => v === p);

export const isCfeInventoryNavPage = (p: unknown): p is CfeInventoryNavPage =>
    [
        CfeInventoryPage.TwentyFourSevenCfeDashboard,
        CfeInventoryPage.PpaFinancialDashboard,
        CfeInventoryPage.ReportingDashboard,
        CfeInventoryPage.ContractTracking,
        CfeInventoryPage.GlobalOverview,
    ].some((v) => v === p);

// SETTINGS
export enum SettingsPage {
    // APP USER
    UserProfile = "user-profile",
    // USERS
    Users = "users",
    UserDetails = "user-details",
    // TENANTS
    TenantInformation = "tenant-information",
    // SITES
    Sites = "sites",
    EditSite = "edit-site",
    CreateSite = "create-site",
    // METERS
    Meters = "meters",
    // CONSUMPTION SITE GROUPS
    ConsumptionSiteGroups = "consumption-site-groups",
    // CONTRACTS
    Contracts = "contracts",
    EditContract = "edit-contract",
    CreateContract = "create-contract",
    // CO2 EMISSION FACTORS
    CO2EmissionFactors = "co2-emission-factors",
    EditCO2EmissionFactor = "edit-co2-emission-factor",
    CreateCO2EmissionFactor = "create-co2-emission-factor",
}

export type SettingsNavPage =
    | SettingsPage.UserProfile
    | SettingsPage.Users
    | SettingsPage.TenantInformation
    | SettingsPage.Sites
    | SettingsPage.Meters
    | SettingsPage.ConsumptionSiteGroups
    | SettingsPage.Contracts
    | SettingsPage.CO2EmissionFactors;

export const isSettingsPage = (p: unknown): p is SettingsPage =>
    Object.values(SettingsPage).some((v) => v === p);

export const isSettingsNavPage = (p: unknown): p is SettingsNavPage =>
    [
        SettingsPage.UserProfile,
        SettingsPage.Users,
        SettingsPage.TenantInformation,
        SettingsPage.Sites,
        SettingsPage.Meters,
        SettingsPage.ConsumptionSiteGroups,
        SettingsPage.Contracts,
        SettingsPage.CO2EmissionFactors,
    ].some((v) => v === p);

export enum AuthPage {
    Login = "login",
    Verify = "verify",
    LoginError = "login-error",
    TenantPending = "tenant-pending",
    TenantCancelled = "tenant-cancelled",
    AuthCallback = "auth-callback",
    NoDisplayOptions = "no-display-options",
}
export type Page = CfeConnectPage | CfeDiagnosticPage | CfeInventoryPage | SettingsPage | AuthPage;
export type NavPage =
    | CfeConnectNavPage
    | CfeDiagnosticNavPage
    | CfeInventoryNavPage
    | SettingsNavPage;

export const isPage = (p: unknown): p is Page =>
    isCfeConnectPage(p) || isCfeDiagnosticPage(p) || isCfeInventoryPage(p) || isSettingsPage(p);

export const isNavPage = (p: unknown): p is NavPage =>
    isCfeConnectNavPage(p) ||
    isCfeDiagnosticNavPage(p) ||
    isCfeInventoryNavPage(p) ||
    isSettingsNavPage(p);

export enum Service {
    BIM = "bim",
    CfeDiagnostic = "cfe-diagnostic",
    GridEmissions = "grid-emissions",
    MeterDataSimulation = "meter-data-simulation",
    MeterData = "meter-data",
    TwentyFourSeven = "twenty-four-seven",
    EacData = "eac-data",
    ReportHub = "report-hub",
}

export enum ResourceAction {
    Read = "read",
    Upsert = "upsert",
    Delete = "delete",
}

export enum ZoneDataAccessLevel {
    Accessible = "Accessible",
    Moderate = "Moderate",
    Challenging = "Challenging",
    Difficult = "Difficult",
    ExtremelyDifficult = "Extremely difficult",
    NoData = "No data",
}

export enum SiteType {
    Consumer = "consumer",
    Producer = "producer",
}

export const isSiteType = (s: unknown): s is SiteType =>
    Object.values(SiteType).includes(s as SiteType);

export const isEnergySource = (s: unknown): s is EnergySource =>
    Object.values(EnergySource).includes(s as EnergySource);

export const isContractType = (c: unknown): c is ContractType =>
    Object.values(ContractType).includes(c as ContractType);

export const isZoneDataAccessLevel = (s: unknown): s is ZoneDataAccessLevel =>
    Object.values(ZoneDataAccessLevel).includes(s as ZoneDataAccessLevel);

export const isService = (s: unknown): s is Service =>
    Object.values(Service).includes(s as Service);

export const isAction = (a: unknown): a is ResourceAction =>
    Object.values(ResourceAction).includes(a as ResourceAction);

export const isIntervalMeterReadingType = (
    t: string,
): t is MeterReadingTypeEnum.IntervalConsumption | MeterReadingTypeEnum.IntervalProduction =>
    t === MeterReadingTypeEnum.IntervalConsumption || t === MeterReadingTypeEnum.IntervalProduction;

export enum MeterReadingTypeEnum {
    IntervalProduction = "interval-production",
    IntervalConsumption = "interval-consumption",
    BillingProduction = "billing-production",
    BillingConsumption = "billing-consumption",
}

export enum ExpectedIntervalReadingType {
    IntervalProduction = "interval-production",
    IntervalConsumption = "interval-consumption",
    IntervalProductionAndConsumption = "interval-production-and-consumption",
    /**
     * In these cases, for each interval you will have either interval-consumption or interval production. If one of the two is populated, the other one should not yield a warning.
     */
    NetMetering = "net-metering",
    None = "none",
}

export enum ExpectedReadingGranularity {
    QuarterHourly = "quarter-hourly",
    HalfHourly = "half-hourly",
    Hourly = "hourly",
    Monthly = "monthly",
    AnyGranularity = "any-granularity",
}

export enum ReadingPendingEventAction {
    Updated = "updated",
    Created = "created",
    Deleted = "deleted",
}

export enum SourceType {
    AnnualLocationBased = "annual-location-based",
    AnnualMarketBased = "annual-market-based",
    HourlyLocationBased = "hourly-location-based",
    HourlyMarketBased = "hourly-market-based",
    Emissionality = "emissionality",
}

export enum ProvidersEnum {
    ApiUpload = "api-upload",
    BigQueryGoogle = "bigquerygoogle",
    C3ntinel = "c3ntinel",
    Ces = "ces",
    CsvUpload = "csv-upload",
    Datadis = "datadis",
    Ealyze = "ealyze",
    Elexon = "elexon",
    Eneco = "eneco",
    Energiemissie = "energiemissie",
    Energinet = "energinet",
    Fingrid = "fingrid",
    MidAmerican = "mid-american",
    TenaskaPowerServices = "tenaska-power-services",
    UtilityAPI = "utilityapi",
    Wattson = "wattson",
    Eddie = "eddie",
    Modity = "modity",
    Semo = "semo",
    Erg = "erg",
    NWCPUD = "nwcpud",
    CEC = "cec",
}

export enum EacProvidersEnum {
    CsvUpload = "csv-upload",
    Cnmc = "cnmc",
}

/** Database ids of EAC registries in EAC Data */
export enum EacRegistryEnum {
    Vector = "vector",
    Recsgse = "recsgse",
    MyVerticer = "myverticer",
    GRexDenmark = "g-rex.denmark",
    Ofgem = "ofgem",
    Cesar = "cesar",
    FinExtra = "finextra",
    pjmGats = "pjm-gats",
    pjmGatsGc = "pjm-gats-gc",
    ercot = "ercot",
    wregis = "wregis",
    eex = "eex",
    evident = "evident",
    Atos = "atos",
    Creg = "creg",
    Brugel = "brugel",
    Vreg = "vreg",
    ECwape = "e-cwape",
    Pronovo = "pronovo",
    TsoCy = "tso-cy",
    Ote = "ote",
    Hknr = "hknr",
    Elering = "elering",
    Lagie = "lagie",
    Certigy = "certigy",
    GRexHungary = "g-rex.hungary",
    CmoGrexel = "cmo.grexel",
    CmoIceland = "cmo.iceland",
    GRexLithuania = "g-rex-lithuania",
    GRexLuxembourg = "g-rex-luxembourg",
    GRexLatvia = "g-rex.latvia",
    Necs = "necs",
    Eego = "eego",
    PoiBorzen = "poi.borzen.si",
    Okte = "okte",
    MRets = "m-rets",
    MRetsGc = "m-rets-gc",
    NarApx = "nar-apx",
    NarApxGc = "nar-apx-gc",
    NcRets = "nc-rets",
    Nvtrec = "nvtrec",
    Nzecs = "nzecs",
    Lgc = "lgc",
    Tge = "tge",
    Gec = "gec",
}

export enum EacCodeUseTypeEnum {
    ConsumptionSite = "consumption-site", // If the eac code is for a consumption site
    ProductionSite = "production-site", // If the eac code is for a production site
    Beneficiary = "beneficiary", // If the eac code is for a whole organization/tenant/beneficiary
    CancellationParty = "cancellation-party", // If the eac code is for the cancellationParty / comercializadora
}

export enum MatchingLogic {
    PayAsConsumed = "pay-as-consumed",
    PayAsProduced = "pay-as-produced",
}

export const isMatchingLogic = (m: unknown): m is MatchingLogic =>
    Object.values(MatchingLogic).includes(m as MatchingLogic);

export enum RoleContractSite {
    Consumer = "consumer",
    Producer = "producer",
}

export enum EmissionsType {
    LocationBased = "location-based",
    MarketBased = "market-based",
}

export enum CfeCoverageMetricsEnum {
    ContractedCfe = "contracted-cfe",
    ExcessCfe = "excess-cfe",
    GridMixCfe = "grid-mix-cfe",
    GridMixNonCfe = "grid-mix-non-cfe",
}

// How to not have this here - should only be defined in BIM
// ../api-client/src/schemas/bim-api-schema.ts cannot import from BIM
export enum TenantStatus {
    Approved = BimDto.TenantStatus.Approved,
    Pending = BimDto.TenantStatus.Pending,
    Rejected = BimDto.TenantStatus.Rejected,
    Cancelled = BimDto.TenantStatus.Cancelled,
}

export enum SourcingMethodEnum {
    DefaultRenewableElectricityFromGrid95NoAllocations = "default_renewable_electricity_from_grid_95_no_allocations",
    DefaultRenewableElectricityFromGridWithEacs = "default_renewable_electricity_from_grid_with_eacs",
    DirectLinePpa = "direct_line_ppa",
    OnSitePpa = "on_site_ppa",
    PhysicalPpa = "physical_ppa",
    ProjectSpecificContract = "project_specific_contract",
    RetailGreenElectricity = "retail_green_electricity",
    UnbundledEacs = "unbundled_eacs",
    VirtualPpa = "virtual_ppa",
    Unknown = "unknown",
}

export enum TrackingInstrumentType {
    Attestation = "attestation",
    EAC = "eac",
}

export enum CompletenessWarning {
    MissingReadings = "MissingReadings",
    IncompleteReadings = "IncompleteReadings",
}

export enum DataGranularity {
    Hourly = "hourly",
    Daily = "daily",
    Monthly = "monthly",
}

export enum SiteLevelWarning {
    Outlier = "Outlier",
    Staleness = "Staleness",
    ExceededInstalledCapacity = "ExceededInstalledCapacity",
}

export enum SanityWarning {
    QuantityNotPositive = "QuantityNotPositive",
    QuantityNotNumber = "QuantityNotNumber",
    StartTimeNotValidDate = "StartTimeNotValidDate",
    EndTimeNotValidDate = "EndTimeNotValidDate",
    StartTimeGreaterThanEndTime = "StartTimeGreaterThanEndTime",
    StartTimeNotMultipleOf15 = "StartTimeNotMultipleOf15",
    EndTimeNotMultipleOf15 = "EndTimeNotMultipleOf15",
    OverlappingReadings = "OverlappingReadings",
    QuantityEstimated = "QuantityEstimated",
    BadFormattedReading = "BadFormattedReading",
    MissingReading = "MissingReading",
    MissingQuantity = "MissingQuantity",
    ConnectionError = "ConnectionError",
    BadTimestamps = "BadTimestamps",
    GranularityForIntervalNotSupported = "GranularityForIntervalNotSupported",
    GranularityForBillingNotSupported = "GranularityForBillingNotSupported",
}

export enum GlobalRegion {
    EMEA = "EMEA",
    APAC = "APAC",
    NA = "NA",
    LATAM = "LATAM",
}
export enum FinancialPerformanceMethodology {
    NetCashflowAverageHourly = "net-cashflow-average-hourly",
    NetCashflowAverageDaily = "net-cashflow-average-daily",
}
