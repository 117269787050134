import { MeterReadingTypeEnum, ProvidersEnum, SiteType } from "./enum";
import { meterDataComponents, meterDataPaths } from "./index";

type NonReadonly<T> = T extends readonly unknown[] // Check if T is a readonly array
    ? NonReadonly<T[number]>[] // Remove readonly from array and apply recursively to elements
    : T extends object // Check if T is an object
      ? {
            -readonly [K in keyof T]: NonReadonly<T[K]>;
        } // Remove readonly from object properties
      : T; // For primitive types, just return T

export type IKind<T> = {
    kind: T;
};

export namespace MeterDataDto {
    export type SyncResponse<T> = {
        data: Array<T>;
        next_cursor: string | null;
        has_more: boolean;
    };

    export type Tenant = {
        tenantId: string;
        name?: string;
    };

    export type TenantProvider = {
        tenantId: string;
        providerId: ProvidersEnum;
        enabled?: boolean | null;
    };

    export enum GoogleBigQueryMeterType {
        IN = 1,
        OUT = 2,
    }

    export enum GoogleBigQueryGranularity {
        HOUR = "hour",
        QUARTER = "quarter",
    }

    export enum GoogleBigQueryEnergyField {
        V = "v",
        KW = "kw",
    }

    export type NoMeterExtraData = NonReadonly<meterDataComponents["schemas"]["NoMeterExtraData"]>;
    export type BigQueryExtraData = NonReadonly<
        meterDataComponents["schemas"]["BigQueryExtraData"]
    >;
    export type CSVExtraData = NonReadonly<meterDataComponents["schemas"]["CSVExtraData"]>;
    export type ElexonExtraData = NonReadonly<meterDataComponents["schemas"]["ElexonExtraData"]>;
    export type WattsonExtraData = NonReadonly<meterDataComponents["schemas"]["WattsonExtraData"]>;
    export type DatadisExtraData = NonReadonly<meterDataComponents["schemas"]["DatadisExtraData"]>;
    export type CesExtraData = NonReadonly<meterDataComponents["schemas"]["CesExtraData"]>;
    export type EnecoExtraData = NonReadonly<meterDataComponents["schemas"]["EnecoExtraData"]>;
    export type EddieExtraData = NonReadonly<meterDataComponents["schemas"]["EddieExtraData"]>;
    export type MidAmericanExtraData = NonReadonly<
        meterDataComponents["schemas"]["MidAmericanExtraData"]
    >;
    export type TenaskaPowerServiceExtraData = NonReadonly<
        meterDataComponents["schemas"]["TenaskaPowerServiceExtraData"]
    >;
    export type ApiUploadExtraData = NonReadonly<
        meterDataComponents["schemas"]["ApiUploadExtraData"]
    >;
    export type SemoMeterExtraData = NonReadonly<
        meterDataComponents["schemas"]["SemoMeterExtraData"]
    >;
    export type ModityMeterExtraData = NonReadonly<
        meterDataComponents["schemas"]["ModityMeterExtraData"]
    >;
    export type UtilityApiExtraData = NonReadonly<
        meterDataComponents["schemas"]["UtilityApiExtraData"]
    >;
    export type NWCPUDMeterExtraData = NonReadonly<
        meterDataComponents["schemas"]["NWCPUDMeterExtraData"]
    >;
    export type ZepakMeterExtraData = NonReadonly<
        meterDataComponents["schemas"]["ZepakMeterExtraData"]
    >;

    export type MeterExtraData = NonReadonly<meterDataComponents["schemas"]["MeterExtraData"]>;

    export type Meter = NonReadonly<meterDataComponents["schemas"]["Meter"]>;

    export type MeterOption = meterDataComponents["schemas"]["MeterOption"];

    /** Meter without extra data */
    export type BasicMeter = meterDataComponents["schemas"]["BasicMeter"];

    export type MeterCreation = meterDataComponents["schemas"]["MeterCreation"];

    export type PutSiteMetersBySiteIdPayload = {
        meterIds: Array<number>;
    };

    export type SiteMeterReading = {
        startTime: Date;
        endTime: Date;
        siteId: string;
        type: MeterReadingTypeEnum;
        quantityWh: number | null;
        //status: string;
        created: Date;
        updated: Date;
        warnings?: Array<string> | null;
        childWarnings?: Array<string> | null;
        outlier: boolean;
        //deleted: Date;
    };

    export type MeterDataSyncResponse = SyncResponse<SiteMeterReading>;

    export type IntervalMeter = meterDataComponents["schemas"]["IntervalMeter"];

    export type GetIntervalMetersResponse =
        meterDataComponents["schemas"]["GetIntervalMetersResponse"];

    export type GetIntervalMetersFiltersResponse =
        meterDataComponents["schemas"]["GetIntervalMetersFiltersResponse"];

    export type GetIntervalMetersPayload =
        meterDataPaths["/{tenantId}/interval-meters"]["get"]["parameters"]["query"] &
            meterDataPaths["/{tenantId}/interval-meters"]["get"]["parameters"]["path"];

    export type GetIntervalMetersFiltersPayload =
        meterDataPaths["/{tenantId}/interval-meters-filters"]["get"]["parameters"]["path"];

    export type BillingMeter = meterDataComponents["schemas"]["BillingMeter"];

    export type GetBillingMetersResponse =
        meterDataComponents["schemas"]["GetBillingMetersResponse"];

    export type GetBillingMetersFiltersResponse =
        meterDataComponents["schemas"]["GetBillingMetersFiltersResponse"];

    export type GetBillingMetersPayload =
        meterDataPaths["/{tenantId}/billing-meters"]["get"]["parameters"]["query"] &
            meterDataPaths["/{tenantId}/billing-meters"]["get"]["parameters"]["path"];

    export type GetBillingMetersFiltersPayload =
        meterDataPaths["/{tenantId}/billing-meters-filters"]["get"]["parameters"]["path"];

    export type GetMeterOptionsPayload =
        meterDataPaths["/{tenantId}/meter-options"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/meter-options"]["get"]["parameters"]["query"];

    export type GetWarningTypeOptionsPayload =
        meterDataPaths["/{tenantId}/warning-type-options"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/warning-type-options"]["get"]["parameters"]["query"];

    export type GetMonitoringSiteDetailsKpisByTenantIdPayload =
        meterDataPaths["/{tenantId}/monitoring-site-details-kpis"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/monitoring-site-details-kpis"]["get"]["parameters"]["query"];

    export type GetAggregatedWarningsPerSitePayload =
        meterDataPaths["/{tenantId}/aggregated-warnings-per-site"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/aggregated-warnings-per-site"]["get"]["parameters"]["query"];

    export type MonitoringSiteDetailsKpis =
        meterDataPaths["/{tenantId}/monitoring-site-details-kpis"]["get"]["responses"]["200"]["content"]["application/json"];

    export type SiteLevelWarning = meterDataComponents["schemas"]["SiteLevelWarning"];
    export type MeterLevelWarningsBreakdown =
        meterDataComponents["schemas"]["MeterLevelWarningsBreakdown"];
    export type SiteLevelWarningsBreakdown =
        meterDataComponents["schemas"]["SiteLevelWarningsBreakdown"];

    export type Credential = {
        name: string;
        description?: string | null;
        secret: string | Record<string, never>;
        expirationDate?: Date;
    };

    export type TotalConsumptionAndProductionKpi = {
        totalConsumptionWh: number | null;
        totalProductionWh: number | null;
    };

    export type ActiveSitesKpi = {
        totalActiveSites: number;
        activeConsumptionSites: number;
        activeProductionSites: number;
    };

    export type RegionOption = {
        regionId: string;
        regionName: string;
    };

    export type BiddingZoneOption = {
        biddingZoneId: string;
        biddingZoneName: string;
    };

    export type SiteOption = {
        siteId: string;
        siteName: string;
        regionName: string;
        biddingZoneName: string;
        timezone: string;
    };

    export type ProviderOption = {
        providerId: string;
        providerName: string;
    };

    export type MeterDataMeterBreakdownRow = {
        meterCode: string;
        consumptionWh: number | null;
        productionWh: number | null;
        numReadings: number;
        expectedNumReadings: number;
        dataCompleteness: number;
        dataProvider: string;
        lastDataUpdate: Date | null;
        dateOfFirstReading: Date | null;
        dateOfLastReading: Date | null;
        currentDelayDays: number | null;
        expectedDelayDays: number | null;
        initialTimeToCheckLocal: Date;
        endTimeToCheckLocal: Date | null;
    };

    export type MeterDataSiteBreakdownRow = {
        siteName: string;
        siteType: SiteType;
        energySource: string | null;
        region: string;
        biddingZone: string;
        timeZone: string;
        consumptionWh: number | null;
        productionWh: number | null;
        numReadings: number;
        expectedNumReadings: number;
        dataCompleteness: number;
        dataProvider: string;
        lastDataUpdate: Date | null;
        dateOfFirstReading: Date | null;
        dateOfLastReading: Date | null;
        currentDelayDays: number | null;
        expectedDelayDays: number | null;
        dataPerMeter?: Array<MeterDataMeterBreakdownRow> | null;
    };

    export type MeterDataSiteBreakdown = {
        rowCount: number;
        rows: Array<MeterDataSiteBreakdownRow>;
    };

    export type HourlyAggregatedMeterData = {
        startTime: Date;
        consumptionWh: number | null;
        productionWh: number | null;
    };

    export type DataCompleteness = {
        dataCompletenessRatio: number;
    };

    export type AggregatedWarningsPerSite =
        meterDataComponents["schemas"]["AggregatedWarningsPerSite"];

    export type GetHourlyMeterWarningsPayload =
        meterDataPaths["/{tenantId}/hourly-meter-warnings"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/hourly-meter-warnings"]["get"]["parameters"]["query"];
    export type SiteHourlyMeterWarnings = meterDataComponents["schemas"]["SiteHourlyMeterWarnings"];

    export type GetHourlySiteWarningsPayload =
        meterDataPaths["/{tenantId}/hourly-site-warnings"]["get"]["parameters"]["path"] &
            meterDataPaths["/{tenantId}/hourly-site-warnings"]["get"]["parameters"]["query"];
    export type SiteHourlySiteWarnings = meterDataComponents["schemas"]["SiteHourlySiteWarnings"];

    export type MetersDataReadingsStatistic =
        meterDataComponents["schemas"]["MeterDataReadingStatistic"];
    export type MetersDataUpdatesStatistic =
        meterDataComponents["schemas"]["MeterDataUpdatesStatistic"];
    export type ConsumptionBySite = {
        page: Array<{
            siteId: string;
            siteName: string;
            countryName: string;
            consumptionWh: number;
        }>;
        totalSites: number;
    };

    export type File = {
        fileId: string;
        tenantId: string;
        userId?: string;
        executionId?: string;
        created: Date;
    };
}

// TODO change it
export namespace ProviderDto {
    type BaseRateLimit = {
        group?: string;
    };

    export type RateLimitEnum =
        | "RateLimitOnSeconds"
        | "RateLimitOnMinutes"
        | "RateLimitOnHours"
        | "RateLimitOnDays"
        | "NoRateLimit";

    export type RateLimitOnSeconds = {
        seconds: number;
        limit: number;
    } & IKind<"RateLimitOnSeconds"> &
        BaseRateLimit;

    export type RateLimitOnMinutes = {
        minutes: number;
        limit: number;
    } & IKind<"RateLimitOnMinutes"> &
        BaseRateLimit;
    export type RateLimitOnHours = {
        hours: number;
        limit: number;
    } & IKind<"RateLimitOnHours"> &
        BaseRateLimit;
    export type RateLimitOnDays = {
        days: number;
        limit: number;
    } & IKind<"RateLimitOnDays"> &
        BaseRateLimit;
    export type NoRateLimit = IKind<"NoRateLimit"> & BaseRateLimit;

    export type RateLimit =
        | NoRateLimit
        | RateLimitOnSeconds
        | RateLimitOnMinutes
        | RateLimitOnHours
        | RateLimitOnDays;

    type CredentialsLevel = "Flexidao" | "TenantProvider" | "Meter" | "NoCredentials";

    export type Provider = {
        providerId: ProvidersEnum;
        recheckAvailable: boolean;
        meterDiscovery: boolean;
        credentialLevel: Array<CredentialsLevel>;
        rateLimits: Array<RateLimit>;
    };
}
