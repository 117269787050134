import { formatNumber, Magnitude, physicalRoundForElectricity } from "@flexidao/ui-lib";
import { Group, Text } from "@mantine/core";
import { ReactElement } from "react";
import { EmptyCell } from "./empty-cell";
import { getClassNameForSubRow } from "./utils";

type NumericCellProps = {
    value: number | null;
    numDecimals?: number;
    units?: string;
    physicalRound?: {
        enablePhysicalRound: true;
        minMagnitude?: Magnitude;
        maxMagnitude?: Magnitude;
    };
    color?: string;
    weight?: "normal" | "semibold" | "bold";
    displaySpaceBetweenNumberAndUnits?: boolean;
    isSubRow?: boolean;
};

const getValueAndMagnitude = ({
    value,
    numDecimals,
    enablePhysicalRound = false,
    minMagnitude,
    maxMagnitude,
}: {
    value: number;
    numDecimals: number;
    enablePhysicalRound: boolean | undefined;
    minMagnitude: Magnitude | undefined;
    maxMagnitude: Magnitude | undefined;
}): [string, Magnitude] => {
    if (enablePhysicalRound) {
        return physicalRoundForElectricity(value, numDecimals, {
            minMagnitude,
            maxMagnitude,
        });
    }

    const valueString: string = formatNumber(value, numDecimals);

    return [valueString, Magnitude.None];
};

const getFontWeight = (weight: "normal" | "semibold" | "bold"): "bold" | "normal" | number => {
    if (weight === "bold") {
        return "bold";
    }

    if (weight === "semibold") {
        /* eslint-disable no-magic-numbers */
        return 600;
    }

    return "normal";
};

const getSpacing = (displaySpaceBetweenNumberAndUnits: boolean): number => {
    /* eslint-disable no-magic-numbers */
    return displaySpaceBetweenNumberAndUnits ? 4 : 2;
};

export const NumberCell = (args: NumericCellProps): ReactElement => {
    const {
        value,
        numDecimals = 0,
        units = "",
        color = "flexidaoGrey.9",
        physicalRound,
        weight = "normal",
        displaySpaceBetweenNumberAndUnits = true,
        isSubRow = false,
    } = args;

    if (value == null || isNaN(value)) {
        return (
            <Group
                sx={{
                    justifyContent: "flex-end",
                }}
            >
                <EmptyCell isSubRow={isSubRow} />
            </Group>
        );
    }

    const [valueString, magnitude]: [string, string] = getValueAndMagnitude({
        value,
        numDecimals,
        enablePhysicalRound: physicalRound?.enablePhysicalRound,
        minMagnitude: physicalRound?.minMagnitude,
        maxMagnitude: physicalRound?.maxMagnitude,
    });
    const valueSplitArray: Array<string> = valueString.split(".");

    const integerPart: string = valueSplitArray[0]!;
    const decimalPart: string | null = valueSplitArray[1] ?? null;

    const fontWeight = getFontWeight(weight);
    const spacing: number = getSpacing(displaySpaceBetweenNumberAndUnits);

    return (
        <Text
            size={14}
            fw={fontWeight}
            c={color}
            sx={{
                justifyContent: "flex-end",
            }}
            className={getClassNameForSubRow(isSubRow)}
        >
            {integerPart}
            {decimalPart && (
                <Text size={11} fw={fontWeight} c={color} span>
                    .{decimalPart}
                </Text>
            )}
            {units != null && (
                <Text size={11} fw={fontWeight} c={color} span ml={spacing}>
                    {magnitude}
                    {units}
                </Text>
            )}
        </Text>
    );
};
